import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config, downloadConfig } from "../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";
import { convertTxtToJson } from "../helpers/csv_helper";

const CsvOrders = (props) => {
  const [loader, setLoader] = useState("");
  const [bannedZips, setBannedZips] = useState([]);
  const [bannedStates, setBannedStates] = useState([]);
  const [bannedCities, setBannedCities] = useState([]);
  const [csvpaydisabled, setCsvpaydisabled] = useState(true);
  const [csvPrice, setCsvPrice] = useState(0);
  const [validateLoader, setValidateLoader] = useState("");
  const [CSV, setCSV] = useState("");
  const [amazonTxt, setAmazonTxt] = useState("");
  const [types, setTypes] = useState([]);
  const [uspsValidateDisabled, setUspsValidateDisabled] = useState(false);
  const [Weight, setWeight] = useState(0);
  const [skus, setSkus] = useState([]);
  const [sort, setSort] = useState("");
  const [csvOrders, setCsvOrders] = useState([]);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState({});
  const [From, setFrom] = useState({
    FromName: "",
    FromStreet: "",
    FromStreet2: "",
    FromCity: "",
    FromState: "",
    FromZip: "",
    FromCompany: "",
    FromCountry: "",
  });

  const [txtData, setTxtData] = useState([]);

  useEffect(() => {
    readLabelTypes();
    readCsvOrders();
    getAddresses();
    readSkus();
  }, []);

  useEffect(() => {
    setFrom({
      FromName: address.name,
      FromStreet: address.street,
      FromStreet2: address.street2,
      FromCity: address.city,
      FromState: address.state,
      FromZip: address.zip,
      FromCompany: address.company,
      FromCountry: address.country,
    });
  }, [address]);

  const getAddresses = async () => {
    await axios
      .get(server + "/api/v1/address/readAll", config)
      .then((res) => {
        setAddresses(res.data.addresses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readLabelTypes = async () => {
    await axios
      .get(server + "/api/v1/labeltype/read", config)
      .then((res) => {
        setTypes(res.data.labelTypes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readSkus = async () => {
    await axios
      .get(server + "/api/v1/sku/read", config)
      .then((res) => {
        setSkus(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateCSV = async (e) => {
    e.preventDefault();

    if (!txtData.length) return toast.error("No data found");
    if (
      !From.FromName ||
      !From.FromStreet ||
      !From.FromCity ||
      !From.FromState ||
      !From.FromZip
    )
      return toast.error("Please select a valid from address");

    setValidateLoader(<Loader />);

    // only send where isChecked is true
    const params = {
      fromAddress: From,
      txtData: txtData.filter((txt) => txt.isChecked),
    };

    await axios
      .post(server + "/api/v1/order/validatecsv", params, config)
      .then((res) => {
        setValidateLoader("");
        setCsvPrice(res.data.totalPrice);
        setTxtData(res.data.data);
        setCsvpaydisabled(false);
        toast.success("CSV validated successfully");
      })
      .catch((err) => {
        setValidateLoader("");
        toast.error(err.response.data.message);
      });
  };

  const createOrderFromCSV = async (e) => {
    e.preventDefault();
    isRequestSent && toast.error("Request already sent");
    setLoader(<Loader />);
    setIsRequestSent(true);

    const params = {
      fromAddress: From,
      txtData: txtData,
    };

    await axios
      .post(server + "/api/v1/order/createOrderFromCSV", params, config)
      .then((res) => {
        toast.success(res.data.message);
        setCsvpaydisabled(true);
        setCSV("");
        setWeight(0);
        setCsvPrice(0);
        setLoader("");
        readCsvOrders();
        setIsRequestSent(false);
        setTxtData([]);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoader("");
        setIsRequestSent(false);
      });
  };

  const readCsvOrders = async () => {
    await axios
      .get(server + "/api/v1/order/read-bulk-orders", config)
      .then((res) => {
        setCsvOrders(res.data.orders);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadZip = async (id) => {
    await axios
      .get(`${server}/api/v1/order/bulk-download/${id}`, downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.zip"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const downloadCSV = async (id) => {
    await axios
      .get(`${server}/api/v1/order/bulk-download-csv/${id}`, downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const downloadPdf = async (id) => {
    await axios
      .get(`${server}/api/v1/order/bulk-download-pdf/${id}`, downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const downloadTxt = async (id) => {
    await axios
      .get(`${server}/api/v1/order/bulk-download-txt/${id}`, {
        ...downloadConfig,
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.txt"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const readBannedZips = async () => {
    await axios
      .get("http://api.endicia.llc/api/v2/misc/bannedZips/read", config)
      .then((res) => {
        setBannedZips(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readBannedStates = async () => {
    await axios
      .get("http://api.endicia.llc/api/v2/misc/bannedStates/read", config)
      .then((res) => {
        setBannedStates(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readBannedCities = async () => {
    await axios
      .get("http://api.endicia.llc/api/v2/misc/bannedCities/read", config)
      .then((res) => {
        setBannedCities(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (amazonTxt) {
      const reader = new FileReader();

      reader.onload = async (event) => {
        var data = await convertTxtToJson(amazonTxt);

        setTxtData(
          data
            .filter((d) => d["order-id"])
            .sort((a, b) => {
              if (sort === "asc") return a["sku"]?.localeCompare(b["sku"]);
            })
            .map((d) => {
              const skuData = skus.find((sku) => sku.SKU === d["sku"]);
              const toZip = d["ship-postal-code"]?.split("-")[0];

              const templates = ["normal", "indica", "stamps", "evs"];

              // check if the zip is banned
              const bannedZip = bannedZips.find((zip) =>
                zip.zips?.includes(toZip)
              );

              // check if the template is banned
              const bannedTemplate = bannedZip?.template; // true or false
              var selectedUspsType = "";
              // choose any template that is not banned
              var template;
              if (bannedTemplate) {
                template = templates.find((t) => t !== bannedTemplate);
                selectedUspsType = "usps_priority";
              }

              const bannedState = bannedStates.find((state) =>
                state.states?.includes(d["ship-state"])
              );

              if (bannedState) {
                template = "normal";
                selectedUspsType = "priorityv3";
              }

              const bannedCity = bannedCities.find((city) =>
                city.cities?.includes(d["ship-city"]?.toLowerCase())
              );

              if (bannedCity) {
                template = "normal";
                selectedUspsType = "priorityv3";
              }

              // check if the From address is banned
              const fromBannedZip = bannedZips.find((zip) =>
                zip.zips?.includes(From.FromZip)
              );

              const fromBannedState = bannedStates.find((state) =>
                state.states?.includes(From.FromState)
              );

              const fromBannedCity = bannedCities.find((city) =>
                city.cities?.includes(From.FromCity?.toLowerCase())
              );

              if (fromBannedZip || fromBannedState || fromBannedCity) {
                template = "normal";
                selectedUspsType = "priorityv3";
              }

              return {
                storeOrderId: d["order-id"],
                purchaseDate: d["purchase-date"],
                toName: d["recipient-name"],
                toStreet1: d["ship-address-1"],
                toStreet2: d["ship-address-2"],
                toCity: d["ship-city"],
                toState: d["ship-state"],
                toZip: toZip,
                toPhone: d["buyer-phone-number"]?.split("ext")[0] || "",
                sku: d["sku"],
                length: d["item-length"] || skuData?.length,
                width: d["item-width"] || skuData?.width,
                height: d["item-height"] || skuData?.height,
                quantity: d["quantity-purchased"],
                itemWeight: d["item-weight"] || skuData?.weight || 0,
                productName: d["product-name"],
                totalWeight: 0,
                labelType: "",
                template: template ? template : skuData?.template,
                selectedUspsType:
                  selectedUspsType || skuData?.labelType?.uid || "",
                price: 0,
                isChecked: false,
                isRecommended: template ? true : false,
              };
            })
        );
      };

      reader.onerror = (error) => {
        console.log(error);
      };

      reader.readAsText(amazonTxt);
    }
  }, [amazonTxt]);

  useEffect(() => {
    readBannedZips();
    readBannedStates();
    readBannedCities();
  }, []);

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Create CSV Order</h5>
                    </div>

                    <div
                      className="card-body"
                      style={{
                        overflowY: "auto",
                        maxHeight: "70vh",
                      }}
                    >
                      <div className="form-group mb-3">
                        <label htmlFor="">
                          Sort by: (Choose this first before adding details to
                          order)
                        </label>
                        <select
                          name="sort"
                          id=""
                          className="form-control"
                          value={sort}
                          onChange={(e) => {
                            setSort(e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          <option value="asc">Ascending</option>
                        </select>
                      </div>

                      <h5 className=" mb-2">From Details</h5>

                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Select a saved address</label>
                        <select
                          name="address"
                          id=""
                          className="form-control"
                          onChange={(e) => {
                            setAddress(
                              addresses.find((a) => a._id === e.target.value)
                            );
                          }}
                        >
                          <option value="">Select address</option>
                          {addresses.map((address) => (
                            <option key={address._id} value={address._id}>
                              {address.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group mb-3">
                            <label className="form-label" htmlFor="groupInput">
                              Amazon TXT File
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="txt"
                              name="txt"
                              required
                              onChange={(e) => {
                                setAmazonTxt(e.target.files[0]);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />

                      {/* create a table */}

                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    setTxtData(
                                      txtData.map((txt) => ({
                                        ...txt,
                                        isChecked: e.target.checked,
                                      }))
                                    );
                                  }}
                                />
                              </th>
                              <th>Order ID/Date&Time</th>
                              <th>Receiver</th>
                              <th>SKU & Weight </th>
                              <th>Provider/Class </th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {txtData.map((order, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={order.isChecked}
                                    onChange={(e) => {
                                      setTxtData(
                                        txtData.map((txt, i) =>
                                          i === index
                                            ? {
                                                ...txt,
                                                isChecked: e.target.checked,
                                              }
                                            : txt
                                        )
                                      );
                                    }}
                                  />
                                </td>
                                <td>
                                  {order.storeOrderId} <br />
                                  {new Date(
                                    order.purchaseDate
                                  ).toLocaleString()}
                                </td>
                                <td>
                                  <div className="d-flex flex-column ">
                                    {/* make input fields */}
                                    <input
                                      type="text"
                                      className="form-control mb-1"
                                      placeholder="Name"
                                      value={order.toName}
                                      onChange={(e) => {
                                        setTxtData(
                                          txtData.map((txt, i) =>
                                            i === index
                                              ? {
                                                  ...txt,
                                                  toName: e.target.value,
                                                }
                                              : txt
                                          )
                                        );
                                      }}
                                    />
                                    <input
                                      type="text"
                                      className="form-control mb-1"
                                      placeholder="Address"
                                      value={order.toStreet1}
                                      onChange={(e) => {
                                        setTxtData(
                                          txtData.map((txt, i) =>
                                            i === index
                                              ? {
                                                  ...txt,
                                                  toStreet1: e.target.value,
                                                }
                                              : txt
                                          )
                                        );
                                      }}
                                    />
                                    <input
                                      type="text"
                                      className="form-control mb-1"
                                      placeholder="Address2"
                                      value={order.toStreet2}
                                      onChange={(e) => {
                                        setTxtData(
                                          txtData.map((txt, i) =>
                                            i === index
                                              ? {
                                                  ...txt,
                                                  toStreet2: e.target.value,
                                                }
                                              : txt
                                          )
                                        );
                                      }}
                                    />
                                    <input
                                      type="text"
                                      className="form-control mb-1"
                                      placeholder="City"
                                      value={order.toCity}
                                      onChange={(e) => {
                                        setTxtData(
                                          txtData.map((txt, i) =>
                                            i === index
                                              ? {
                                                  ...txt,
                                                  toCity: e.target.value,
                                                }
                                              : txt
                                          )
                                        );
                                      }}
                                    />
                                    <input
                                      type="text"
                                      className="form-control mb-1"
                                      placeholder="State"
                                      value={order.toState}
                                      onChange={(e) => {
                                        setTxtData(
                                          txtData.map((txt, i) =>
                                            i === index
                                              ? {
                                                  ...txt,
                                                  toState: e.target.value,
                                                }
                                              : txt
                                          )
                                        );
                                      }}
                                    />
                                    <input
                                      type="text"
                                      className="form-control mb-1"
                                      placeholder="Postal Code"
                                      value={order.toZip}
                                      onChange={(e) => {
                                        setTxtData(
                                          txtData.map((txt, i) =>
                                            i === index
                                              ? {
                                                  ...txt,
                                                  toZip: e.target.value,
                                                }
                                              : txt
                                          )
                                        );
                                      }}
                                    />
                                    <input
                                      type="text"
                                      className="form-control mb-1"
                                      placeholder="Phone Number"
                                      value={order.toPhone}
                                      onChange={(e) => {
                                        setTxtData(
                                          txtData.map((txt, i) =>
                                            i === index
                                              ? {
                                                  ...txt,
                                                  toPhone: e.target.value,
                                                }
                                              : txt
                                          )
                                        );
                                      }}
                                    />
                                  </div>
                                </td>

                                <td>
                                  SKU{" "}
                                  {skus.find((sku) => sku.SKU === order.sku)
                                    ? "Found"
                                    : "Not Found"}{" "}
                                  ({order.sku}) <br />
                                  Item Size: (Length x Width x Height) <br />
                                  {/* create input */}
                                  <div className="d-flex mb-1">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Length"
                                      value={order.length}
                                      onChange={(e) => {
                                        setTxtData(
                                          txtData.map((txt, i) =>
                                            i === index
                                              ? {
                                                  ...txt,
                                                  length: e.target.value,
                                                }
                                              : txt
                                          )
                                        );
                                      }}
                                    />{" "}
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Width"
                                      value={order.width}
                                      onChange={(e) => {
                                        setTxtData(
                                          txtData.map((txt, i) =>
                                            i === index
                                              ? {
                                                  ...txt,
                                                  width: e.target.value,
                                                }
                                              : txt
                                          )
                                        );
                                      }}
                                    />{" "}
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Height"
                                      value={order.height}
                                      onChange={(e) => {
                                        setTxtData(
                                          txtData.map((txt, i) =>
                                            i === index
                                              ? {
                                                  ...txt,
                                                  height: e.target.value,
                                                }
                                              : txt
                                          )
                                        );
                                      }}
                                    />
                                  </div>
                                  Quantity:
                                  <input
                                    type="number"
                                    className="form-control w-50 mb-1"
                                    value={order.quantity}
                                    onChange={(e) => {
                                      setTxtData(
                                        txtData.map((txt, i) =>
                                          i === index
                                            ? {
                                                ...txt,
                                                quantity: e.target.value,
                                              }
                                            : txt
                                        )
                                      );
                                    }}
                                  />
                                  Per Item Weight (in LBS):
                                  <input
                                    type="number"
                                    className="form-control w-50"
                                    value={order.itemWeight}
                                    onChange={(e) => {
                                      setTxtData(
                                        txtData.map((txt, i) =>
                                          i === index
                                            ? {
                                                ...txt,
                                                itemWeight: e.target.value,
                                              }
                                            : txt
                                        )
                                      );
                                    }}
                                  />
                                  Total Weight:{" "}
                                  {order.quantity * order.itemWeight || 0}
                                  {" LBS"}
                                  <br />
                                  Description:
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={order.productName}
                                    onChange={(e) => {
                                      setTxtData(
                                        txtData.map((txt, i) =>
                                          i === index
                                            ? {
                                                ...txt,
                                                productName: e.target.value,
                                              }
                                            : txt
                                        )
                                      );
                                    }}
                                  />
                                </td>
                                <td>
                                  Please choose Provider
                                  <div className="form-group mb-3">
                                    <select
                                      name="labeltype"
                                      id=""
                                      className="form-control"
                                      value={order.selectedUspsType}
                                      onChange={(e) => {
                                        setTxtData(
                                          txtData.map((txt, i) =>
                                            i === index
                                              ? {
                                                  ...txt,
                                                  selectedUspsType:
                                                    e.target.value,
                                                }
                                              : txt
                                          )
                                        );
                                      }}
                                    >
                                      <option value="">Select LabelType</option>
                                      {types.map((type, ind) => (
                                        <option key={ind} value={type.uid}>
                                          {type.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  {(order.selectedUspsType === "priority" ||
                                    order.selectedUspsType === "express") && (
                                    <>
                                      Template{" "}
                                      {order.isRecommended
                                        ? " (Recommended)"
                                        : ""}
                                      <div className="form-group mb-3">
                                        <select
                                          onChange={(e) => {
                                            setTxtData(
                                              txtData.map((txt, i) =>
                                                i === index
                                                  ? {
                                                      ...txt,
                                                      template: e.target.value,
                                                    }
                                                  : txt
                                              )
                                            );
                                          }}
                                          required
                                          name="template"
                                          className="form-control"
                                          value={order.template}
                                        >
                                          <option value=""> Select type</option>

                                          {order.selectedUspsType ===
                                            "priority" && (
                                            <>
                                              <option value="pitney">
                                                Pitney Bowes
                                              </option>
                                              <option value="indica">
                                                Endicia
                                              </option>
                                              <option value="stamps">
                                                Stamps
                                              </option>
                                              <option value="evs">EVS</option>
                                            </>
                                          )}

                                          {order.selectedUspsType ===
                                            "express" && (
                                            <>
                                              <option value="evs">EVS</option>
                                            </>
                                          )}
                                        </select>
                                      </div>
                                    </>
                                  )}
                                </td>
                                <td>{"$" + order.price || "-"}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="card-footer">
                      <div className="d-flex justify-content-between align-items-center mt-5">
                        <div>
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            disabled={isRequestSent || csvpaydisabled}
                            onClick={createOrderFromCSV}
                          >
                            Pay ${csvPrice} {loader}
                          </button>
                        </div>
                        <div>
                          <button
                            className="btn btn-gray mr-2"
                            disabled={uspsValidateDisabled}
                            onClick={validateCSV}
                          >
                            Validate {validateLoader}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-4">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">CSV Orders</h5>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Order Date</th>
                              <th>Orders</th>
                              <th>Status</th>
                              <th>Price</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {csvOrders.map((order, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {new Date(order.createdAt).toDateString()}
                                </td>
                                <td>{order.orders?.length}</td>
                                <td>
                                  {order.status === "completed" ? (
                                    <span className="badge badge-success">
                                      Completed
                                    </span>
                                  ) : (
                                    <span className="badge badge-warning">
                                      Pending
                                    </span>
                                  )}
                                </td>
                                <td>${order.total}</td>
                                <td>
                                  {order.status === "completed" && (
                                    <>
                                      <button
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                          downloadZip(order._id);
                                        }}
                                      >
                                        <em className="icon ni ni-download"></em>
                                        Zip
                                      </button>
                                      <button
                                        className="btn btn-sm btn-primary ml-2"
                                        onClick={() => {
                                          downloadCSV(order._id);
                                        }}
                                      >
                                        {/* download icon */}
                                        <em className="icon ni ni-download"></em>
                                        CSV
                                      </button>
                                      <button
                                        className="btn btn-sm btn-primary ml-2"
                                        onClick={() => {
                                          downloadPdf(order._id);
                                        }}
                                      >
                                        {/* download icon */}
                                        <em className="icon ni ni-download"></em>
                                        PDF
                                      </button>

                                      <button
                                        className="btn btn-sm btn-primary ml-2"
                                        onClick={() => {
                                          downloadTxt(order._id);
                                        }}
                                      >
                                        {/* download icon */}
                                        <em className="icon ni ni-download"></em>
                                        TXT
                                      </button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CsvOrders;
